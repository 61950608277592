import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const Map = () => (
  <GoogleMap
    defaultZoom={12}
    defaultCenter={{
      lat: 50.980752,
      lng: 7.070467,
    }}
  >
    <Marker
      position={{
        lat: 50.980752,
        lng: 7.070467,
      }}
      title="Möhl GmbH & Co. KG"
      clickable
    />
  </GoogleMap>
);

export default withScriptjs(withGoogleMap(Map));
