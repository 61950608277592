import React from "react";
import Img from "gatsby-image";

import Layout from "../layouts/index";
import Map from "../components/Map";
import Contact from "../images/contact.jpg";

const ContactPage = (props) => (
  <Layout {...props}>
    <div>
      <div className="container">
        <Img fluid={props.data.contact.childImageSharp.fluid} />

        <div className="row section-margin-bottom">
          <div className="col-sm-6">
            <h3>Kontakt</h3>
            <p>
              Möhl GmbH & Co. KG
              <br />
              Möhlstraße 24
              <br />
              51069 Köln
              <br />
              Telefon: <a href="tel:+49221681066">0221 / 68 10 66</a>
              <br />
              Telefax: <a href="tel:+492216804760">0221 / 680 47 60</a>
              <br />
              E-Mail:{" "}
              <a href="mailto:info@moehl-koeln.de">info@moehl-koeln.de</a>
              <br />
              <br />
              Öffnungszeiten:
              <br />
              Montag bis Freitag: 9:00 bis 12:30
            </p>
          </div>
          <div className="col-sm-6">
            <h3>Ansprechpartner</h3>
            <p>
              Geschäftsführer: <br />
              <strong>Frederik Möhl</strong>
              <br />
              <br />
              Assistenz der Geschäftsführung:
              <br />
              <strong>Nicole Glowatzki</strong>
            </p>
          </div>
        </div>
        <div className="row section-margin-bottom">
          <div className="col-sm-12" />
          <form method="POST" action="//formspree.io/philipheinser@me.com">
            <input type="hidden" value="/contact" name="success_callback" />
            <input type="hidden" value="/contact" name="error_callback" />
            <div className="col-md-4">
              <div className="form-group">
                <label for="name">Name</label>
                <input
                  name="content[name]"
                  value=""
                  id="name"
                  type="text"
                  placeholder="Name"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label for="email">E-Mail</label>
                <input
                  name="content[email]"
                  value=""
                  id="email"
                  type="email"
                  placeholder="E-Mail"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label for="phone">Telefon</label>
                <input
                  name="content[phone]"
                  value=""
                  id="phone"
                  type="phone"
                  placeholder="Telefon"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group col-md-12">
              <label for="subject">Nachricht</label>
              <textarea
                name="content[message]"
                id="subject"
                rows="5"
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-md-12">
              <button type="submit" className="btn btn-primary">
                Nachricht senden
              </button>
            </div>
          </form>
        </div>
      </div>
      <Map
        loadingElement={<div id="map" />}
        containerElement={<div id="map" />}
        mapElement={<div style={{ height: `100%` }} />}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC43R4D2uZblYW63d58JPn015w6WGDsWa0"
      />
    </div>
  </Layout>
);

export default ContactPage;

export const query = graphql`
  query {
    contact: file(relativePath: { eq: "contact.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
